/*-- 
-- REFERENCE FOR MEDIA QUERY WIDTH --
max-width 320px	Smartwatches
max-width 420px	Smaller devices
max-width 600px	Phones
min-width 600px	Tablets and Large Phones
min-width 768px	Tablets
min-width 992px	Laptops and Desktops
min-width 1200px	Monitors, Desktops
--*/

@media (min-width: 992px) {
    header {
        background: none;
    }
    .prod-list-container {
        padding-left: 0 !important;
    }
    cx-page-slot.LegalEntitySelector .legal-entity-container .legal-entity-options {
        left: unset !important;
        right: 0 !important;
    }
    
    /* Order Details - Start */
    .delivery-info-container {
        display: grid !important;
        grid-template-columns: 1fr 1fr;
        column-gap: 15px;
    }
    /* Order Details - End */
}

@media only screen and (max-width: 992px) {
    cx-page-layout.ProductGridPageTemplate {
        flex-direction: column;
        .no-products-container {
            padding: 0 15px;
            a {
                min-height: fit-content !important;
            }
        }
    }

    .ProductGridPageTemplate .ProductLeftRefinementSlot {
        max-width: 100% !important;
    }

    cx-page-layout.ContentPage1Template {
        flex-direction: column !important;
    }

    cx-page-layout .Section2Aaccessrequests,
    cx-page-layout .Section2Aquotations,
    cx-page-layout .Section2Aorders,
    cx-page-layout .Section2Aproducts {
        max-width: 100% !important;
    }

    /* Footer - Start */
    .footer-container {
        display: block !important;
    }
    .footer-contactus-container {
        max-width: 100% !important;
        padding: 0 !important;

        &>div {
            padding: 1rem !important;
        }
    }
    .footer-image-container {
        max-width: 100% !important;
        padding: 0 !important;
    }
    div.footer-nav-container {
        flex-direction: column;
        padding: 1.5rem 0 0 0 !important;
        margin: 0 !important;

        &>div {
            max-width: 100%;
        }
    }
    .copyright-container {
        max-width: 100%;
        margin-top: 0.7rem;
        padding: 0;

        .copyright-text {
            display: block;
            text-align: center;
            padding: 0 0 2em 0 !important;

            &>div {
                display: inline;
            }
        }
    }
    .icon-cont {
        .social-icon:has(i.fa-x-twitter),
        .social-icon:has(i.fa-linkedin-in) {
            align-items: unset !important;
        }
        .social-icon:has(i.fa-facebook-f),
        .social-icon:has(i.fa-instagram) {
            align-items: center !important;
        }
    }
    cx-page-slot.FooterAddressAndContactInfo {
        margin-top: 1rem;
    }
    cx-page-slot.FooterNavigation {
        .d-flex {
            justify-content: center !important;
            margin: 0 1rem !important;
        }
    }
    .footer-image {
        min-height: 9.5rem;
        max-width: 100% !important;
    }
    cx-page-slot.FooterSocialMediaLinks {
        &>app-shared-link {
            .icon-cont {
                margin-right: 1rem !important;
            }
        }
    }
    /* Footer - End */

    /* PLP - Start */
    .prod-pagination-top {
        position: static !important;
    }
    .prod-list-container {
        padding-left: 0.9rem;
    }
    /* PLP - End */

    /* Header - Start */
    .header {
        cx-page-slot.PreHeader {
            position: absolute;
            right: 0;
            top: 1rem;
            z-index: 10;

            button.cx-hamburger {
                padding: 0.5rem 0.8rem 0.3rem 0.8rem;
                border: 1px solid #b3b3b3;
                margin: 0 1rem;
            }

            .hamburger-inner,
            .hamburger-inner::before,
            .hamburger-inner::after {
                background-color: #999999;
            }
        }
        cx-page-slot.SiteLogo {
            margin: 1rem 0 1rem 1rem;
        }
    }

    .searchbar-comp,
    .minicart-comp {
        margin: 0 !important;
    }

    header#cx-header {
        cx-page-layout.navigation {
            position: absolute;
            top: 3.3rem;
            z-index: 9;
            padding: 0.5rem;
            height: fit-content;
            background-color: var(--cx-color-light) !important;

            a.nav-link {
                min-height: 3rem;
            }

            cx-page-slot.SiteLogin {
                cx-login {
                    &>div.ng-star-inserted {
                        padding: 0 0.5rem;
                    }
                }
            }

            cx-page-slot.SignIn {
                .user-icon-mobile {
                    display: none;
                    height: 0 !important;
                }
                .login-options {
                    display: block;
                    position: static;
                    border: 0;

                    .dropdown-item {
                        background-color: rgb(241, 241, 241);
                        border: 0;
                        padding: 0.9rem 0.5rem;
                        display: block;
                        min-height: fit-content;
                        line-height: 1.1rem;
                        margin-top: 0;

                        &:hover {
                            color: rgb(60, 60, 60);
                            text-decoration: underline;
                        }
                    }
                }
            }
            cx-page-slot.NavigationBar {
                margin: 0 !important;

                div.cstm-menubar {
                    flex-grow: 1;
                    background: var(--cx-color-light);

                    &>a.p-menubar-button {
                        display: none;
                    }

                    &>p-menubarsub.p-element {
                        flex-grow: 1;

                        &>ul.p-menubar-root-list {
                            display: flex;
                            position: relative;
                            background: var(--cx-color-light);
                            box-shadow: none;

                            &>li.p-menuitem {
                                background-color: var(--cx-color-light) !important;
                                width: 100%;

                                &>div.p-menuitem-content:hover {
                                    background-color: var(--cx-color-light) !important;
                                }

                                &>div.p-menuitem-content>a.p-menuitem-link {
                                    margin-top: 0;
                                    min-height: 3rem;
                                }

                                p-menubarsub.p-element {
                                    &>ul.p-submenu-list {
                                        width: 100%;
                                        padding: 0;
                                        border: 1px solid #c6c6c6;
                                        border-radius: 0;
                                    }
                                }

                                a.p-menuitem-link:has(angledownicon) {
                                    display: flex;
                                    justify-content: start;

                                    angledownicon {
                                        margin-left: 0.2rem;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        cx-page-slot.SocialMediaLinkHeader {
            justify-content: start;
            margin-bottom: 1.5rem;

            &>app-shared-link {
                flex-basis: unset;
                flex-grow: unset;
                .social-icon {
                    min-height: fit-content;
                }
                .social-icon.fa-x-twitter {
                    position: relative;
                    top: 0;
                }
            }
        }
    }
    cx-page-slot.LanguageSelector {
        .lang-selector-container,
        .lang-selector-options {
            width: 100%;
        }
        .lang-selector-options {
            position: relative !important;
            background-color: var(--light_grey) !important;

            &>.dropdown-item {
                height: fit-content;
                padding: 1rem !important;

                &:hover {
                    background-color: #d1d1d1 !important;
                }
            }
        }
    }
    cx-page-slot.LegalEntitySelector {
        .legal-entity-container,
        .legal-entity-options {
            width: 100%;
        }
        .legal-entity-options {
            position: relative !important;
            background-color: rgba(0, 0, 0, 0) !important;
            margin-bottom: 1rem !important;
            min-width: unset !important;

            &>.dropdown-item {
                height: fit-content;
                padding: 1rem;
            }
        }
        .loading-txt {
            margin: 0 0.5rem 2rem 0.5rem;
        }
    }
    /* Header - End */

    /* Shipping Accordions - Start */
    .shipping-details-container {
        .dropdowns {
            flex-direction: column;

            &>div p-dropdown {
                position: relative !important;
                display: block;
            }

            &>div .dropdown-custom {
                width: 100%;
            }

            .shipping-address-container,
            .shipping-method-container {
                max-width: 100% !important;
                margin-top: 1rem;
            }
        }
    }
    /* Shipping Accordions - End */

    /* Profile Review Details - Start */
    .profile-review-details-container {
        display: block !important;

        &>div {
            max-width: 100%;
        }
    }
    /* Profile Review Details - End */

    /* Filter Popup - Start */
    .p-multiselect p-overlay>div.p-overlay {
        left: -1.25rem !important;
        .p-multiselect-items li {
            overflow: visible;
            width: fit-content;
        }
    }

    .p-overlaypanel {
        width: 350px !important;
        .p-multiselect p-overlay>div.p-overlay {
            width: 347px !important;
        }
        .p-multiselect {
            width: 100% !important;
        }
    }
    /* Filter Popup - End */

    /* Checkout - Start */
    .checkout-dropdowns-container>div {
        padding: 0;
    }
    /* Checkout - End */
}

@media only screen and (max-width: 768px) {
    /* PDP - Start */
    cx-page-layout.ProductDetailsPageTemplate {
        grid-template-areas:
            'summaryA summaryA'
            'summaryB summaryB'
            'alternateProduct alternateProduct'
            'relatedProduct relatedProduct' !important;
    }
    /* PDP - End */

    /* Order History - Start */
    .order-stats {
        &>div {
            margin-bottom: 0.5rem;
        }
    }
    /* Order History - End */

    /* Invite User - Start */
    .invite-user-form-container {
        .inp-element {
            width: 100% !important;
        }

        .legal-entity-container {
            display: block !important;
            &>p-dropdown>div.p-dropdown {
                width: 100%;
            }
            &>.addBtn {
                margin: 0.5rem 0 !important;
            }
        }
    }
    /* Invite User - End */

    /* Order Details - Start */
    cx-page-layout.AccountPageTemplate {
        grid-template-areas:
            'TopContentA TopContentA '
            'TopContentB TopContentB '
            'MiddleContent MiddleContent  '
            'BottomContent  BottomContent ' !important;

        .information-text {
            max-width: 100%;
        }
    }
    /* Order Details - End */

    /* Mini Cart - Start */
    cx-page-slot.SearchBox .minicart-comp>.CartSelector {
        padding: 0;
    }
    /* Mini Cart - End */

    /* Filter Popup - Start */
    .p-overlaypanel {
        width: 320px !important;
        .p-multiselect p-overlay>div.p-overlay {
            width: 317px !important;
        }
        .p-multiselect {
            width: 100% !important;
        }
    }
    /* Filter Popup - End */

    cx-page-layout.OrderHistoryPageTemplate {
        .to-field div.p-datepicker {
            left: unset !important;
            right: 0 !important;
        }
        .from-field div.p-datepicker {
            left: 0 !important;
            right: unset !important;
        }
        .p-datepicker table th {
            padding: 0.6rem !important;
        }
    }
}

@media only screen and (min-width: 768px) {
    /* Mini Cart - Start */
    .minicart-container {
        margin-top: 0 !important;
    }
    /* Mini Cart - End */

    .drag-drop-upload-container {
        width: 50%;
    }
    .obsolete-label {
        padding-bottom: 3.9rem;
    }
}

@media only screen and (max-width: 420px) {
    .p-paginator {
        padding: 0.5rem 0 !important;
    }
    .cstm-pagination.p-paginator .p-paginator-first,
    .cstm-pagination.p-paginator .p-paginator-last {
        min-width: fit-content !important;
    }

    /* Filter Popup - Start */
    .p-overlaypanel {
        width: 280px !important;
        .p-multiselect p-overlay>div.p-overlay {
            width: 277px !important;
        }
        .p-multiselect {
            width: 100% !important;
        }
    }
    /* Filter Popup - End */

    /* Header - Start */
    .header {
        cx-page-slot.PreHeader {
            button.cx-hamburger {
                padding: 0.5rem 0.5rem 0.3rem 0.5rem;
                margin: 0 1rem 0 0;
            }
            .hamburger-box {
                width: 1.1rem;
            }
            .hamburger-inner,
            .hamburger-inner::before,
            .hamburger-inner::after {
                width: 1.1rem;
            }
        }
    }
    /* Header - End */
}